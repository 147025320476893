@import "../../../common/styles/colors.scss";

.formRetailer {
  padding: 0px 100px;
  height: 100%;
  .formTitle {
    color: #8F0A0E;
    text-align: center;
    font-family: 'Space-Grotesk', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 48.162px;
    letter-spacing: 5.76px;
    text-transform: uppercase;
    padding: 40px;
  }

  .fields {
    margin-top: 20px;
    width: 100%;
    .fieldWrapper {
      width: 100%;
      font-family: 'Space-Grotesk', sans-serif;
      .field {
        height: 42px;
        color: $clr-dark-blue;
        width: 100%;
        border-radius: 32px !important;
        font-family: 'Space-Grotesk', sans-serif;
      }
      .fieldTitleWine {
        width: 300px;
        color: #000;
        font-family: 'Space-Grotesk', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 48.162px;
      }
      .checkbox {
        margin-top: 10px;
      }
      .checkboxTitle {
        font-size: 20px;
      }
    }
  }

  .buttonGroup {
    margin-bottom: 20px;
    margin-top: 40px;
    width: 100%;
    .button {
      border-radius: 32px;
      border-color: $clr-retailer-primary;
      background: $clr-retailer-primary!important;
      display: flex;
      width: 100%;
      height: 48px;
      padding: 15.859px 27.187px;
      justify-content: center;
      align-items: center;
      gap: 11.328px;
      color: #FFF;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      span {
        font-family: 'Space-Grotesk';
      }
    }
  }

  .buttonLink {
    color: #000;
    text-align: center;
    font-family: 'ProximaNova-Semibold', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 48.162px;
    text-decoration-line: underline;
  }
  .poweredBy {
    font-size: 17px;
    img {
        vertical-align: middle;
        margin-bottom: 5px;
    }
  }
}

.formShadow {
    box-shadow: 0 13px 12px rgba(0,0,0,0.1);
    border-radius: 30px;
    background-color: rgba(255,255,255,0.5);
    padding: 40px 20px;
    height: calc(100% - #{$productHubLogin});
    display: block;
}

.fieldTitleWine {
  width: 300px;
  color: #000;
  font-family: 'Space-Grotesk', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 48.162px;
}
.poweredBy,
.poweredBy a {
  text-align: center;
  padding-top: 40px;
  color: #000;
  text-align: center;
  font-family: 'Space-Grotesk', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 48.162px;
}
.poweredBy a {
  font-weight: 700;
}