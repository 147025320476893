@import "../../../common/styles/colors.scss";

.form {
  width: 530px;
  height: 472px;
  margin: 0 auto;

  .formTitle {
    text-align: center;
    line-height: 35px;
    background-color: $clr-gainsboro;
    height: 35px;
    border-radius: 20px;
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-end;
    .button {
      width: 250px;
      height: 35px;
      background-color: $clr-accent;
      border: none;
      border-radius: 8px;
      &:not([disabled]):hover {
        border: $clr-accent !important;
        outline: none;
      }
    }
  }

  .selectTags {
    position: relative;;
    width: 100%;
    color: $clr-dark-blue;
    font-size: 15px;
    margin: 20px 0 0 0;
    .errorCompanies {
      position: absolute;
      left: 0;
      top: 34px;
      font-size: 12px;
      color: $clr-red;
    }
  }

  .fieldWrapper {
    height: 75px;
  }

  .field {
    height: 35px;
  }

  .userInfoFields {
    display: flex;
    justify-content: space-between;

    .userInfoFieldsLeft,
    .userInfoFieldsRight {
      width: 49%;
      margin-top: 20px;
    }

    .userInfoFieldsRight {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
