@import '../../../common/styles/colors.scss';

.draftItemsFormModal {
    position: relative;
    min-width: 1000px !important;
    margin: 60px 0;

    .ant-modal-header {
        border-radius: 8px 8px 0 0;
        display: flex;
        align-items: center;
    }
}
