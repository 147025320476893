@import "../../styles/colors.scss";

.descriptor {
    border-radius: 4px;
    padding: 4px;
    text-align: center;
}

.active {
    border-width: 6px !important;
}

.descriptorPL {
    cursor: pointer;
    margin-left: 1px;
    border-radius: 6px;
    padding: 4px 6px;
    width: 35px;
    text-align: center;
    background-color: $clr-hierarchy-bg-pl;
    color: $clr-hierarchy-pl;
    border: 2px solid $clr-hierarchy-pl;
    font-weight: bold;
}

.descriptorCS {
    cursor: pointer;
    margin-left: 1px;
    border-radius: 6px;
    padding: 4px 6px;
    width: 35px;
    text-align: center;
    background-color: $clr-hierarchy-bg-cs;
    color: $clr-hierarchy-cs;
    border: 2px solid $clr-hierarchy-cs;
    font-weight: bold;
}

.descriptorSHR {
    cursor: pointer;
    margin-left: 1px;
    border-radius: 6px;
    padding: 4px 6px;
    width: 35px;
    text-align: center;
    background-color: $clr-hierarchy-bg-shr;
    color: $clr-hierarchy-shr;
    border: 2px solid $clr-hierarchy-shr;
    font-weight: bold;
}

.descriptorEA {
    cursor: pointer;
    margin-left: 1px;
    border-radius: 6px;
    padding: 4px 6px;
    width: 35px;
    text-align: center;
    background-color: $clr-hierarchy-bg-ea;
    color: $clr-hierarchy-ea;
    border: 2px solid $clr-hierarchy-ea;
    font-weight: bold;
}

.descriptorOther {
    cursor: pointer;
    margin-left: 1px;
    border-radius: 6px;
    padding: 4px 6px;
    text-align: center;
    background-color: $clr-hierarchy-bg-other;
    color: $clr-hierarchy-other;
    border: 2px solid $clr-hierarchy-other;
    font-weight: bold;
}

.big {
    border-width: 3px;
    border-radius: 12px;
    font-size: 18px;
    padding: 8px;
    vertical-align: middle;
}