@import "../../styles/colors-vindix.scss";
@import "../../styles/colors.scss";

.sidebar {
  display: flex;
  width: 250px;
  min-width: 250px;
  min-height: 100vh;
  background-color: $clr-dark-red;
  flex-direction: column;
  justify-content: space-between;

  .header {
    position: relative;
    background-color: $clr-white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .headerLogo {
    width: 100%;
    height: 84px;
    padding: 15px 45px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 400;

    .logout,
    .user {
      display: flex;
      align-items: center;
      margin: 5px;
      padding: 10px;
      border-radius: 8px;
      background-color: $clr-dark-red;
      color: $clr-white;

      .userTitle,
      .logoutTitle {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $clr-white;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .logout {
      &:hover {
        opacity: 0.8;
      }
    }

    .iconAvatar, .iconLogout {
      margin-right: 8px;
      min-width: 36px;
      min-height: 36px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }

    .iconLogout {
      & > path {
        fill: $clr-accent;
      }
    }

    .iconAvatar {
      & > path, &  > circle:last-child {
        stroke: $clr-accent;
      }
    }
  }

  a.active>.nav__link {
    background-color: $clr-very-light-grey;
    color: $clr-blue-v2;
    text-decoration: none;
    color: red;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
.outlinedIcon {
  padding-left: 20px;
  padding-right: 10px;
}

.outlinedIcon svg {
  width: 24px;
  height: 24px;
}

.userCompaniesSelect {
  background: $clr-very-dark-red;
  padding: 23px 15px;
  height: 90px;
}
