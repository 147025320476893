@import '../../../common/styles/colors.scss';

.multiImageUploader {
  .ant-upload-list-picture-card-container, .ant-upload-select-picture-card {
    width: 139px;
    height: 139px;
    margin: 5px;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
    margin-top: 12px;
    font-size: 63px;
  }

  .ant-upload-list-picture-card-container:active {
    border: 1px solid $clr-grey-dark;
  }
}