@import '../../common/styles/colors.scss';

.headerLogo {
  margin-right: 10px;

  & > circle {
    fill: $clr-accent;
    opacity: 0.2;
  }

  & > g > path {
    fill: $clr-accent;
  }
}

.wrapper {
    font-family: 'Space-Grotesk', sans-serif;
}