@import "../../../common/styles/colors.scss";

.wrapper {
  display: flex;
  align-items: center;
  width: calc(100vw - 900px);
  min-width: 300px;

  .searchIcon {
    margin-left: 12px;
  }

  .buttonFilter,
  .buttonFilterActive {
    display: flex;
    align-items: center;
    min-width: 104px;
    width: calc(100vw - 1050px);
    font-size: 16px;
    height: 35px;
    background-color: $clr-blue-transparent;
    border: none;
    border-radius: 8px;
    &:not([disabled]):hover {
      border: $clr-blue-transparent;
      outline: none;
    }

    .buttonFilterName {
      margin-left: 10px;
      color: $clr-dark-blue;
    }

    &:hover {
      background-color: $clr-blue-steel;
    }

    &:hover > .buttonFilterName {
      color: $clr-white;
    }
  }

  .buttonFilterActive {
    background-color: $clr-blue-steel;
    .buttonFilterName {
      color: $clr-white;
    }
  }

  .filterControlFieldShow {
    position: absolute;
    padding: 20px;
    top: 45px;
    left: 0;
    width: calc(100vw - 270px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $clr-white;
    border: 1px solid $clr-blue-transparent-v2;
    border-radius: 4px;
    z-index: 100;
    .selectTagsField {
      background-color: $clr-blue-transparent;
      color: $clr-dark-blue;
      font-size: 15px;
      margin-right: 20px;
      width: calc(100vw - 590px);
    }

    .selectSortedByField {
      min-width: 120px;
      margin-right: 20px;
      text-align: center;
      color: $clr-grey-dark;
      background-color: $clr-blue-transparent;
    }

    .buttonGroup {
      display: flex;
      align-items: center;
      .buttonSet {
        margin-right: 20px;
        min-width: 90px;
        font-size: 16px;
        height: 32px;
        font-size: 14px;
        background-color: $clr-blue-transparent;
        border: none;
        border-radius: 8px;
        &:not([disabled]):hover {
          border: $clr-blue-transparent;
          outline: none;
        }
        &:last-child {
          margin-right: 0;
        }
      }

      .buttonSet {
        background-color: $clr-accent;
        color: $clr-white;
      }

      .closeFilterButton {
        cursor: pointer;
        color: $clr-grey-dark;
        &:hover {
          color: $clr-blue-steel;
        }
      }
    }
  }

  .filterControlFieldHide {
    display: none;
  }
}
