@import '../../styles/colors.scss';

.wrapper {
    display: flex;
    width: 100vw;
    height: 100vh;
    background: rgb(255,255,255);
    background: radial-gradient(circle at top, rgba(191,162,214,1) 0%, rgba(191,162,214,1) 11%, rgba(120,35,181,1) 100%);
    background-position: center top !important;
    //background: radial-gradient(circle at top, red 0, blue, green 100%);

    .leftColumn,
    .rightColumn {
        width: 50%;
    }

    .rightColumn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255,255,255, 0.6);
    }

    .leftColumn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //background-color: $clr-deep-blue-dark;
        .text {
            color: $clr-white;
            font-size: 20px;
            // text-align: center;
            width: 74%;
            margin: 0 auto;
        }

        .trustedLogo {
            width: 100%;
            margin: 60px auto;
        }

        .monitorsLogo {
            margin: 0 auto;
            width: 80%;
        }
    }
}

@media (min-width: 768px) and (max-width: 1245px) {
    .leftColumn {
        .text {
            font-size: 18px !important;
        }
    }
}

.wineWrapper {
    display: flex;
    width: 100vw;
    height: 100vh;

    .leftColumn,
    .rightColumn {
        width: 50%;
    }

    .rightColumn {
        background-color: $clr-white;
        padding: 40px;
    }

    .leftColumn {
        background-image: url(../../icons/bgvindix.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
}

.retailerWrapper {
    font-family: 'Space-Grotesk', sans-serif;
    display: flex;
    width: 100vw;
    height: 100vh;

    background-image: url(../../img/product_hub/login_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .retailerLogoLogin {
        height: $productHubLogin;
        margin: 40px 0 0 50px;
    }

    .rightColumn {
        padding: 40px 50px;
        width: 30%;
    }

    .leftColumn {
        padding: 40px 50px;
        width: 70%;
        h1 {
            font-size: 38px;
            font-weight: bold;
            font-family: 'Space-Grotesk', sans-serif;
            span {
                color: #E16C43;
            }
        }
    
        p {
            font-family: 'Space-Grotesk', sans-serif;
            font-size: 18px;
        }

        .retailerLeftBlock {
            padding: 0px 60px;
        }
    }
    
}