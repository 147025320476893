@import '../../../common/styles/colors.scss';
@import '../../../common/styles/colors-retailer.scss';

.retailerFont {
    table {
        font-family: 'Space-Grotesk', sans-serif !important;
    }
    tr {
        font-family: 'Space-Grotesk', sans-serif !important;
    }
    td {
        font-family: 'Space-Grotesk', sans-serif !important;
    }
    font-family: 'Space-Grotesk', sans-serif !important;
}

.infoBlockLabel {
    color: $clr-primary;
    font-weight: bold;
    margin-top: 10px;
    font-family: 'Space-Grotesk', sans-serif;
}

.infoBlockAmount {
    color: #000;
    font-weight: bold;
    margin-top: 5px;
    font-size: 35px;
    font-family: 'Space-Grotesk', sans-serif;
}

.infoBlock {
    background: $clr-white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    svg {
        path {
            stroke: $clr-primary;
        }
    }
}

h1 {
    margin: 0 0 30px 0;
    font-family: 'Space-Grotesk', sans-serif;
}

h2 {
    color: $clr-primary;
    font-size: 30px;
    text-transform: capitalize;
    font-weight: bold;
    font-family: 'Space-Grotesk', sans-serif;
}

.wrapper {
    background-color: $clr-white;
    border-radius: 12px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 0 20px 20px 20px;
    //height: calc(100vh - 230px);
    overflow: auto;
}
