@import "../../../common/styles/colors.scss";

.switchFieldWrapper {
  width: 45%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .fieldName {
    width: 150px;
    color: $clr-grey-blue;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}