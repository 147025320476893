@import '../../../common//styles/colors.scss';

.buttonSave {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    font-size: 16px;
    height: 35px;
    border-radius: 8px;
    border: none;
    background-color: $clr-accent;
    color: $clr-white;

    &:hover {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        font-size: 16px;
        height: 35px;
        border-radius: 8px;
        border: none;
        background-color: $clr-accent;
        color: $clr-white;
    }
}

.wrap_for_button {
    display: flex;
    justify-content: flex-end;
}
