@import "../../../common/styles/colors.scss";

.draftProductsFormItemsViewWrapper {
  .itemTr,
  .itemTrTL,
  .itemTrPL,
  .itemTrMX,
  .itemTrCS,
  .itemTrDS,
  .itemTrPK,
  .itemTrEA {
    display: flex;
    justify-content: space-between;

    .itemTd, .itemTdGtin {
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid $clr-light-grey;
      height: 32px;
      &:nth-child(1) {
        padding-left: 32px;
        min-width: 175px;
        max-width: 175px;
      }
      &:nth-child(2) {
        display: block;
        line-height: 32px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &:nth-child(3),
      &:nth-child(4) {
        min-width: 127px;
        max-width: 127px;
        justify-content: center;
      }
    }

    .itemTdGtin {
      cursor: pointer;
      &:hover {
        color: $clr-blue-steel;
      }
    }
  }

  .itemTrPL::before,
  .itemTrMX::before,
  .itemTrCS::before,
  .itemTrDS::before,
  .itemTrPK::before,
  .itemTrEA::before {
    display: flex;
    align-items: center;
    content: "";
    margin: 16px 10px 0 0px;
    height: 2px;
    background-color: $clr-blue-steel-dark;
  }

  .itemTrPL::before {
    min-width: 7.5px;
    max-width: 7.5px;
  }

  .itemTrMX::before {
    min-width: 15px;
    max-width: 15px;
  }

  .itemTrCS::before {
    min-width: 22.5px;
    max-width: 22.5px;
  }

  .itemTrDS::before {
    min-width: 30px;
    max-width: 30px;
  }

  .itemTrPK::before {
    min-width: 37.5px;
    max-width: 37.5px;
  }

  .itemTrEA::before {
    min-width: 45px;
    max-width: 45px;
  }
}
