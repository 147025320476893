@import "../../../common/styles/colors.scss";

.droppableTable {
  width: 100%;
  margin-bottom: 17px;

  .listHeader {
    display: flex;
    justify-content: space-between;

    .listTh {
      display: flex;
      align-items: center;
      background-color: $clr-light-grey;
      color: $clr-dark-blue;
      width: 100%;
      font-size: 12px;
      height: 32px;
      &:nth-child(1) {
        padding-left: 32px;
        min-width: 175px;
        max-width: 175px;
      }
      &:nth-child(3),
      &:nth-child(4) {
        min-width: 127px;
        max-width: 127px;
        justify-content: center;
      }
    }
  }
}

.listTbody,
.listTbodyItemsField,
.listTbodyItemsFieldDragging {
  overflow: scroll;
  overflow-y: unset;
  padding: 0;
  height: calc(100vh - 590px);
  max-height: 322px;
}

.emptyList,
.loadingWrapper {
  height: calc(100vh - 590px);
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .emptyIcon {
    & > circle {
      opacity: 0.2;
    }
  }

  .emptyText {
    margin-top: 10px;
    color: $clr-gainsboro;
  }
}

.listTr,
.listTrTL,
.listTrPL,
.listTrMX,
.listTrCS,
.listTrDS,
.listTrPK,
.listTrEA,
.listTrDragging,
.listTrCopy {
  display: flex;
  justify-content: space-between;

  .listTd, .listTdGtin {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $clr-light-grey;
    height: 32px;
    &:nth-child(1) {
      padding-left: 32px;
      min-width: 175px;
      max-width: 175px;
    }
    &:nth-child(2) {
      display: block;
      line-height: 32px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &:nth-child(3),
    &:nth-child(4) {
      min-width: 127px;
      max-width: 127px;
      justify-content: center;
    }
  }

  .listTdGtin {
    cursor: pointer;
    &:hover {
      color: $clr-blue-steel;
    }
  }
  
  .listInput {
    display: flex;
    align-items: center;
    max-width: 90px;
    min-width: 90px;
    border-bottom: 1px solid $clr-light-grey;
    height: 32px;
  }
}

.listTrPL::before,
.listTrMX::before,
.listTrCS::before,
.listTrDS::before,
.listTrPK::before,
.listTrEA::before {
  display: flex;
  align-items: center;
  content: "";
  margin: 16px 10px 0 10px;
  height: 2px;
  background-color: $clr-blue-steel-dark;
}

.listTrPL::before {
  min-width: 7.5px;
  max-width: 7.5px;
}

.listTrMX::before {
  min-width: 15px;
  max-width: 15px;
}

.listTrCS::before {
  min-width: 22.5px;
  max-width: 22.5px;
}

.listTrDS::before {
  min-width: 30px;
  max-width: 30px;
}

.listTrPK::before {
  min-width: 37.5px;
  max-width: 37.5px;
}

.listTrEA::before {
  min-width: 45px;
  max-width: 45px;
}

.listTrCopy {
  .listTd {
    border: none;
    background-color: $clr-lightblue;
  }
}

.listTrDragging {
  .listTd {
    border: none;
    background-color: $clr-accent;
  }
}

.listTbodyItemsField,
.listTbodyItemsFieldDragging {
  min-height: 202px;
  max-height: calc(100vh - 710px);
  border: 1px dashed $clr-light-grey;
  width: 100%;
}

.listTbodyItemsFieldDragging {
  border: 1px dashed $clr-accent;
}

.dragableWrapper {
  height: 75vh;
}

.itemDeleteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 36px;
  max-width: 36px;
}

.uploadWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .uploadIcon {
    width: 60px;
    height: 60px;
    & > circle {
      fill: $clr-accent;
      opacity: 0.2;
    }

    & > path {
      stroke: $clr-accent;
      stroke-width: 1;
    }
  }

  .uploadText {
    font-size: 18px;
    margin-top: 15px;
    color: $clr-blue-steel-dark;
  }
}
