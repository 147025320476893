@import "../../../common//styles/colors.scss";

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 620px;
  min-width: 400px;
  margin: 0 40px;
  height: 543px;
  align-items: center;

  .formTitle {
    width: 100%;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 50px;
    font-weight: bold;
    color: $clr-accent;
  }

  .checkbox {
    margin-top: 25px;
    display: flex;
    align-items: unset;
    background-color: #ffffff;
    .checkboxTitle {
      font-size: 16px;
      color: $clr-blue-sea;
    }
  }

  .fields {
    width: 100%;
    .fieldWrapper {
      height: 94px;
      margin-bottom: 15px;
      .field {
        height: 48px;
        color: $clr-dark-blue;
      }
      .fieldTitle {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .buttonGroup {
    margin-top: 30px;
    width: 100%;
    .button {
      width: 100%;
      height: 48px;
      font-size: 18px;
      background-color: $clr-accent!important;
      border: none;
      border-radius: 8px;
      &:not([disabled]):hover {
        border: $clr-accent !important;
        outline: none;
      }
    }
  }

  .buttonLink {
    text-align: center;
    margin-top: 35px;
    font-size: 13px;
    color: $clr-smoke;
    text-decoration: none;
    border: none;
    box-shadow: none;
  }
}

.wineForm {
  padding: 40px 100px;
  .formTitle {
    color: #8F0A0E;
    text-align: center;
    font-family: 'Inter-Regular', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 48.162px;
    letter-spacing: 5.76px;
    text-transform: uppercase;
    padding-top: 40px;
  }

  .fields {
    width: 100%;
    .fieldWrapper {
      width: 100%;
      .field {
        height: 48px;
        color: $clr-dark-blue;
        width: 100%;
      }
      .fieldTitleWine {
        width: 300px;
        color: #000;
        font-family: 'Inter-Regular', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 48.162px;
      }
    }
  }

  .buttonGroup {
    margin-bottom: 40px;
    margin-top: 40px;
    width: 100%;
    .button {
      border-radius: 12px;
      border-color: $clr-dark-red;
      background: $clr-dark-red!important;
      display: flex;
      width: 100%;
      height: 60px;
      padding: 15.859px 27.187px;
      justify-content: center;
      align-items: center;
      gap: 11.328px;
      color: #FFF;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .buttonLink {
    color: #000;
    text-align: center;
    font-family: 'Inter-Regular', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 48.162px;
    text-decoration-line: underline;
  }
}

.fieldTitleWine {
  width: 300px;
  color: #000;
  font-family: 'Inter-Regular', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 48.162px;
}
.poweredBy,
.poweredBy a {
  text-align: center;
  padding-top: 40px;
  color: #000;
  text-align: center;
  font-family: 'Inter-Regular', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 48.162px;
}
.poweredBy a {
  font-weight: 700;
}