@import '../../../common/styles/colors.scss';

.itemFormFieldFormModal {
    top: 40px;
}

.draftItemsFormModal {
    min-height: 600px;
    // min-width: 675px !important;
}

.field {
    width: 200px;
}

.itemFormModalWithDinamicField {
    width: 1000px !important;
    border: none;
    border-radius: 0 !important;
}

.draftItemsFormModalWide {
    width: 1100px !important;
}

.buttonsWrapper {
    width: 190px;
    position: absolute;
    display: flex;
    flex-direction: column;
    left: -184px;
    top: 0;
    background: #f3f4f8;
    min-height: 730px;
    max-height: 730px;
    overflow-y: scroll;
    border-radius: 0 0 0 8px;
    background-color: #2c4258;

    &::-webkit-scrollbar {
        width: 7px;
        border: none;
        outline: none;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #2c4258;
        // outline: 1px solid slategrey;
    }

    .button {
        min-height: 45px;
        margin-left: 2px;
        border: none;
        color: white;
        box-shadow: none;
        overflow: hidden;
        background-color: #2c4258;
        display: flex;
        align-items: center;
        &:disabled {
            background-color: $clr-grey;
        }
        &:disabled:hover {
            color: $clr-white;
        }
    }
    .disableBtn {
        min-height: 45px;
        cursor: not-allowed;
        margin-left: 2px;
        border-radius: 0 !important;
        background-color: #2c4258;
        color: $clr-white;
        border: none;
        box-shadow: none;
        overflow: hidden;
        display: flex;
        align-items: center;
        &:disabled {
            background-color: $clr-grey;
        }
        &:disabled:hover {
            color: $clr-white;
        }
    }

    .buttonChecked {
        border-radius: 8px;
        min-height: 45px;
        border: none;
        color: rgb(49, 133, 198);
        background-color: white;
        user-select: none;
        display: flex;
        align-items: center;
    }
}

.tableTitle {
    display: flex;
    align-items: center;
    .tableTitleText {
        color: $clr-accent;
        margin-left: 10px;
    }
    .tableTitleLogo > circle {
        fill: $clr-accent;
        opacity: 0.3;
    }

    .tableTitleLogo > g > path {
        fill: $clr-accent;
    }
}
