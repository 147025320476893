@import "../../styles/colors.scss";

.container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 45px;
  height: 84px;
  background-color: $clr-white;
  color: $clr-black;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px; 
  box-shadow: 3px 0 6px rgba(0,0,0,0.2);
  z-index: 3;
  position: relative;
}