@import "../../styles/colors.scss";

.headerNutritional {
	border-radius: 10px;
	border: 2px solid #EDEDED;
	background: #FFF;
	color: #000;
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 16px;
	margin: 5px;
}
