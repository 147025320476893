@import '../../../common/styles/colors.scss';

.itemFormFieldForm {
    // overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 26px 0px 27px;

    &::-webkit-scrollbar {
        width: 7px;
        border: none;
        outline: none;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        // outline: 1px solid slategrey;
    }

    .ant-select .ant-select-selector {
        background-color: $clr-blue-transparent;
        border-radius: 8px;
    }

    .ant-switch {
        background-color: $clr-accent;
        border: $clr-accent;
    }

    .ant-switch-checked {
        background-color: $clr-green;
        border: $clr-green;
    }
}
.itemFormFieldDinamicForm {
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 700px;
    max-height: 700px;
    padding: 0px 26px 0px 27px;
    border-radius: 8px 0 0 8px;

    .ant-modal-content {
        min-height: 730px !important;
        border-radius: 0 8px 8px 0;
    }

    &::-webkit-scrollbar {
        width: 7px;
        border: none;
        outline: none;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        // outline: 1px solid slategrey;
    }
    
    .ant-switch-checked {
        background-color: $clr-green!important;
        border: $clr-green!important;
    }
}
