@import '../../../common/styles/colors.scss';

.fieldWrapper {
    display: flex;
    justify-content: space-between;
    height: 63px;

    .fieldName {
        margin-top: 6px;
        color: $clr-grey-blue;
    }

    .field,
    .selectField,
    .dataField {
        width: 305px;
    }

    .selectField {
        border-radius: 8px;
    }
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;

    .buttonSave,
    .buttonDiscard {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        font-size: 16px;
        height: 35px;
        border-radius: 8px;
        border: none;
        &:not([disabled]):hover {
            border: $clr-accent;
            outline: none;
        }
    }

    .buttonSave {
        background-color: $clr-accent;
        color: $clr-white;
    }
    .buttonDiscard {
        color: $clr-dimgray;
        background: none;
        margin-right: 10px;
        cursor: pointer;
    }
}

.switchFieldWrapper {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;

    .switchTitle {
        margin-right: 182px;
    }
}

.optionsWrapper {
    .buttonOptionAdd {
        border-radius: 8px;
        padding: 0 8px;
    }

    .fieldOptions {
        width: 260px;
    }

    .fieldOptionsName {
        width: 214px;
    }

    .optionsList {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .optionItem {
            display: flex;
            align-items: center;
            .optionItemName {
                width: 262px;
                padding: 3px 10px;
                text-align: center;
                border: 1px solid $clr-accent;
                color: $clr-accent;
                border-radius: 8px;
                margin-bottom: 10px;
            }

            .optionItemDelete {
                display: flex;
                align-items: center;
                height: 30px;
                margin: 0 6px 10px 14px;
                cursor: pointer;
            }

            &:last-child {
                margin-bottom: 20px;
            }
        }
    }
}

.icon {
    width: 23px;
    height: 37px;
    object-fit: contain;
    margin: -31px 0 0 10px;
    cursor: pointer;
}

.tabFieldWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selectFieldTab {
    width: 270px !important;
}
