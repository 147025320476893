.description,
.descriptionAdmin {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.description {
  width: 35vw;
}

.descriptionAdmin {
  width: 28vw;
}

.descriptor, .descriptorAdmin {
  min-width: 82px;
}


.quantity, .quantityAdmin {
  min-width: 101px;
}

.emptyStart {
  min-width: 22px;
}

.emptyEnd {
  width: 2px;
  min-width: 2px;
}

.emptyEndAdmin {
  width: 10px;
  min-width: 10px;
}

.emptyCenter {
  width: 0px;
}

.emptyCenterAdmin {
  width: 150px;
}

.gtin {
  min-width: 120px;
}

@media (min-width: 600px) and (max-width: 1288px) {
  .description,
  .descriptionAdmin {
    width: 150px;
  }
  .emptyEnd, .emptyEndAdmin {
    width: 8px;
  }
  .emptyCenter {
    width: 0px;
  }
}

@media (min-width: 600px) and (max-width: 1176px) {
  .description,
  .descriptionAdmin {
    width: 100px;
  }
  .emptyEnd, .emptyEndAdmin {
    width: 10px;
  }
  .emptyCenterAdmin {
    width: 94px;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .description,
  .descriptionAdmin {
    width: 80px;
  }
  .emptyEnd, .emptyEndAdmin {
    width: 15px;
  }
  .emptyCenterAdmin {
    width: 74px;
  }
}
