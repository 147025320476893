@import '../common/styles/colors.scss';

.headerLogo {
  margin-right: 10px;

  & > circle {
    fill: $clr-accent;
    opacity: 0.2;
  }

  & > g > rect {
    stroke: $clr-accent;
  }
  width: 36px;
  height: 36px
}

.wrapper {
  background: white;
  padding: 20px;

  .fieldWrapper {
    margin: 20px 0;
    width: 305px;
    display: flex;
    align-items: center;
    height: 40px;
  }

  .additionalWrapper {
    padding: 36px 23px;
    background: #f4f4ff;
    border-radius: 8px;
    width: 352px;

    .fieldWrapper {
      margin: 20px 0;
      width: 305px;
      display: flex;
      align-items: center;
      height: 40px;
    }
  }

  .flexWrap {
    display: flex;
    width: 645px;
    align-items: center;
    justify-content: space-between;
  }

  .qr {
    position: absolute;
    top: 333px;
    left: 750px;

    img {
      width: 120px;
    }
  }

  .saveBtn {
    width: 650px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: -40px 0 0 0;
  }

  .button {
    background-color: #e16d25;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    font-size: 16px;
    box-shadow: none;
    height: 35px;
    border-radius: 8px;
    color: white;

    &:not([disabled]):hover {
      border: $clr-accent !important;
      outline: none;
    }
  }

  .selectField {
    width: 305px !important;
    border-radius: 8px;
  }
}
