@import "../../../common/styles/colors.scss";

.userAccountInfo {
  margin-top: 20px;
  .ant-select {
    border-radius: 8px;
  }
  
  .ant-select .ant-select-selector {
    background-color: $clr-blue-transparent;
    border-radius: 8px;
    height: 32px;
  }

  .ant-select-selection-item {
    font-size: 15px;
    color: $clr-dark-blue;
  }

  .ant-select-selection-placeholder {
    font-size: 15px;
  }

  .ant-select-clear {
    background: none;
  }
}