@import "../colors.scss";

.ant-checkbox {
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    background-color: $clr-gainsboro;
  }

  .ant-checkbox-input + .ant-checkbox-inner {
    border: 1px solid $clr-gainsboro;
  }

  .ant-checkbox-input:checked + .ant-checkbox-inner {
    background-color: $clr-accent;
    border: 1px solid $clr-accent;
  }
}

.ant-checkbox-checked:after {
  border: $clr-accent;
}

.ant-checkbox-checked .ant-checkbox-inner {
  &::after {
    border: 2px solid $clr-gainsboro;
    border-top: 0;
    border-left: 0;
    left: 5px;
  }
}
