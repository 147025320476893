@import '../colors.scss';

// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   border: none;
// }

// .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   border: none;
//   box-shadow: none;
// }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $clr-gainsboro;;
}