@import '../../../common/styles/colors.scss';

.inputLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  background-color: $clr-accent;
  border-radius: 8px;
  margin-top: 5px;
  color: $clr-white;
  margin-top: 20px;
  &:hover {
    opacity: 0.9;
  }
  .inputFile {
    display: none;
  }
}