@import "../colors.scss";

.ant-pagination-item {
  border: none!important;
}

.ant-pagination-item:hover a {
  color: $clr-black!important;
}

.ant-pagination-item-active:hover a {
  color: $clr-white!important;
}

.ant-pagination-item-active {
  background-color: $clr-dimgray!important;
  border: $clr-dimgray!important;
}

.ant-pagination-item-active > a {
  color: $clr-white!important;
}

.ant-pagination-next .ant-pagination-item-link {
  border: none;
  &:hover {
    color: $clr-black;
  }
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-item-link {
  border: none!important;
  background-color: $clr-white!important;
  &:hover {
    color: $clr-black!important;
  }
}

.ant-pagination-item-link:hover {
  border: none!important;
}