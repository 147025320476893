@import "../../../common/styles/colors.scss";

.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  position: relative;

  .buttonAdd {
    font-size: 16px;
    min-width: 90px;
    box-shadow: none;
    height: 35px;
    background-color: $clr-accent;
    border: none;
    border-radius: 8px;
    &:not([disabled]):hover {
      border: $clr-accent;
      outline: none;
    }
  }

  .filterControlWrapper {
    display: flex;
    align-items: center;
    width: calc(100vw - 485px);
    min-width: 500px;
    .countInfoWrapper {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .countInfoField {
        background-color: $clr-blue-transparent;
        width: 40px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        margin-right: 5px;
        border-radius: 8px;
      }
      .countInfoTitle {
        min-width: 155px;
      }
    }
  }
}
