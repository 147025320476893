@import "../../../common/styles/colors.scss";

.tableWrapper {
  height: auto;
}

.table {
  .mainCheckbox {
    font-size: 18px;
    color: $clr-grey-dark;
    position: relative;
    // &::before {
    //   content: "#";
    //   display: block;
    //   position: absolute;
    //   left: 22px;
    //   z-index: 20;
    // }
  }

  .emptyIcon {
    & > circle {
      opacity: 0.2;
    }
  }

  .buttonStatusInvalidate,
  .buttonStatusValidate {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 26px;
    font-size: 10px;
    border-radius: 30px;

    .buttonIcon {
      margin-right: 8px;
    }
  }

  .buttonStatusValidate {
    background-color: $clr-green;
    color: $clr-white;
    border: none;
    box-shadow: none;
  }
  
  .buttonStatusInvalidate {
    background-color: $clr-accent;
    color: $clr-white;
    border: none;
    box-shadow: none;
  }

  .date {
    min-width: 80px;
  }

  .gtin {
    min-width: 120px;
    &:hover {
      color: $clr-blue-steel;
      cursor: pointer;
    }
  }

  .description {
    width: 35.2vw;
  }

  .descriptionAdmin {
    width: 28vw;
  }

  .description,
  .descriptionAdmin,
  .companyName {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.footerWrapper {
  display: flex;
  justify-content: flex-end;
  .pagination {
    margin: 10px 0 8px 0;
  }
}

@media (min-width: 600px) and (max-width: 1288px) {
  .table {
    .companyName,
    .description,
    .descriptionAdmin {
      width: 150px;
    }
    .brand,
    .descriptor {
      width: 70px;
    }
  }
}

@media (min-width: 600px) and (max-width: 1176px) {
  .table {
    .companyName,
    .description,
    .descriptionAdmin {
      width: 100px;
    }
    .brand,
    .descriptor {
      width: 60px;
    }
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .table {
    .companyName,
    .description,
    .descriptionAdmin {
      width: 80px;
    }
  }
}
