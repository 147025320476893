$clr-blue: #21B2E2;
$clr-blue-v2: #3185C6;
$clr-dark-blue: #2C4258;
$clr-blue-transparent: rgba(49, 133, 198, 0.1);
$clr-gray-transparent: rgb(243, 243, 243);
$clr-blue-transparent-v2: rgba(49, 133, 198, 0.2);
$clr-grey-blue: #334A61;
$clr-light-grey: rgb(231, 232, 233);
$clr-accent: $clr-grey-blue!default;
$clr-accent-dark: #df6518!default;
$clr-light-orange: #E16D25;
$clr-green: #25E19C;
$clr-blue-steel: rgb(0,160,198);
$clr-blue-steel-dark: rgb(50,133,198);
$clr-grey: rgb(184, 185, 187);
$clr-grey-dark: rgb(144, 151, 158);
$clr-slategray: rgb(132,149,166);
$clr-gainsboro: rgb(220,226,231);
$clr-dimgray: rgb(87,84,84);
$clr-white: rgb(255, 255, 255);
$clr-light-blue: #8495a6;
$clr-lavender: #e1eaf2;
$clr-light-iceblue: #ecf3fb;
$clr-red: rgb(255, 57, 57);
$clr-black: black;
$clr-deep-blue-dark: #27343B;
$clr-smoke: #8495A6;
$clr-blue-sea: #2C4258;
$clr-lightblue: lightblue;
$clr-dark-red: #8F0A0E;
$clr-very-light-grey: #EDEDED;
$clr-very-dark-red: #72070B;
$clr-mostly-white: #F9F9F9;
$clr-dark-grey: #777777;
$clr-strong-orange: #B5680D;
$clr-dark-lime-green: #0C8F0A;
$clr-very-light-gray: #F2F2F2;
$clr-lighter-gray: #F9F9F9;
$clr-retailer-primary: #E16C43;
$productHubLogin: 80px;
$clr-primary: #8F0A0E!default;
$clr-hierarchy-pl: #CC9301;
$clr-hierarchy-cs: #FF6A6A;
$clr-hierarchy-shr: #0FB073;
$clr-hierarchy-ea: #31A8FF;
$clr-hierarchy-bg-pl: #FFF7E4;
$clr-hierarchy-bg-cs: #FFE4E4;
$clr-hierarchy-bg-shr: #E6FFF6;
$clr-hierarchy-bg-ea: #E4F4FF;
$clr-hierarchy-other: #CCCCCC;
$clr-hierarchy-bg-other: #EEEEEE;
$clr-input-title: $clr-blue!default;