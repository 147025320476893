@import '../../../common/styles/colors.scss';
.fieldWrapper {
    display: flex;
    justify-content: space-between;
    width: 77.5%;

    .inputOptions {
        display: flex;
        width: 320px;
        justify-content: space-between;
    }

    .fieldName {
        margin-top: 6px;
        width: 150px;
        color: $clr-grey-blue;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .field,
    .selectField {
        width: 150px;
    }

    .field {
        margin-right: 32px;
    }

    .selectField {
        margin-bottom: 20px;
    }
}
