@import '../../common/styles/colors.scss';

.headerLogo {
  margin-right: 10px;

  & > circle {
    fill: $clr-accent;
    opacity: 0.2;
  }

  & > g > rect {
    stroke: $clr-accent;
  }
  width: 36px;
  height: 36px
}

.wrapper {
  background: white;
  padding: 20px;

  .fieldWrapper {
    margin: 20px 0;
    width: 305px;
    display: flex;
    align-items: center;
    height: 40px;
  }

  .additionalWrapper {
    padding: 36px 23px;
    background: #f4f4ff;
    border-radius: 8px;
    width: 352px;

    .fieldWrapper {
      margin: 20px 0;
      width: 305px;
      display: flex;
      align-items: center;
      height: 40px;
    }
  }

  .flexWrap {
    display: flex;
    width: 645px;
    align-items: center;
    justify-content: space-between;
  }

  .qr {
    position: absolute;
    top: 333px;
    left: 750px;

    img {
      width: 120px;
    }
  }

  .saveBtn {
    width: 650px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: -40px 0 0 0;
  }

  .button {
    background-color: #e16d25;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    font-size: 16px;
    box-shadow: none;
    height: 35px;
    border-radius: 8px;
    color: white;

    &:not([disabled]):hover {
      border: $clr-accent !important;
      outline: none;
    }
  }

  .selectField {
    width: 305px !important;
    border-radius: 8px;
  }
}
.saveButton,
.saveButton button {
    cursor: pointer;
    background-color: $clr-dark-red!important;
    border-radius: 8px;
    border: 2px solid $clr-dark-red;
    background: $clr-dark-red;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-width: 128px;
    &:not([disabled]):hover {
      border: 2px solid $clr-dark-red!important;
      background-color: $clr-dark-red!important;
      color: $clr-white!important;
      padding: 5px 10px;
      border: 2px solid $clr-dark-red;
    }
}
.wrapButton {
    min-height: 35px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: flex-end;
}
.generateElabel,
.generateElabelDisable {
    display: inline-flex;
    height: 50px;
    padding: 9.43px 16.165px;
    justify-content: center;
    align-items: center;
    gap: 6.735px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 2px solid $clr-dark-red;
    background: $clr-dark-red;
    color: $clr-white;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}
.generateElabelDisable {
  pointer-events: none;
  color: rgba(0,0,0,.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.active {
    right: 100px !important;
    transition: all 0.4s;
}
.ant-tabs-tab.ant-tabs-tab-active {
    background: blue!important;
}
.qrCodeReady {
  color: $clr-black;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px;
}
.qrCodeImg {
  border-radius: 28px;
  border: 7px solid #8F0A0E;
  padding: 20px;
}
