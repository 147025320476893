@import '../../common/styles/colors.scss';

body,
.publicProduct {
	background: $clr-very-light-gray;

	img.productImg {
		padding: 45px 75px;
		border-radius: 25%;
		border: 1px solid transparent;
	}
	.name {
		color: #000;
		text-align: center;
		font-family: 'Inter-Regular', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 5px;
		text-transform: uppercase;
		padding-bottom: 10px;
	}
	.brand {
		color: #000;
		text-align: center;
		font-family: 'Inter-Regular', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		padding-bottom: 10px;
	}
	.origin {
		color: #000;
		text-align: center;
		font-family: 'Inter-Regular', sans-serif;
		font-size: 12.308px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding-bottom: 43px;
	}
	.header {
		background: $clr-dark-red;
		display: flex;
		height: 50px;
		padding: 20px 10px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
		color: #FFF;
		font-family: 'Inter-Regular', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
	.text {
		color: #000;
		font-family: 'Inter-Regular', sans-serif;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		padding: 20px;
	}
	.responsibleText {
		padding-left: 20px;
		padding-bottom: 80px;
	}
	.responsibleText div{
		color: #000;
		font-family: 'Inter-Regular', sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 500!important;
		line-height: normal;
	}
	.additional {
		border-bottom: 1px solid #BDBDBD;
	}
	.additional div {
		color: #000;
		font-family: 'Inter-Regular', sans-serif;
		font-size: 18px;
		font-style: normal;
		font-weight: 500!important;
		line-height: normal;
	}
	.nutritionalHeader div {
		color: #000;
		font-family: 'Inter-Regular', sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 700!important;
		line-height: normal;
		border-bottom: 1px solid #000;
		padding: 10px;
	}
}

