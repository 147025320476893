@import '../../../common/styles/colors.scss';

.tableTitle {
  display: flex;
  align-items: center;
  .tableTitleText {
    color: $clr-accent;
    margin-left: 10px;
  }
  .tableTitleLogo > circle {
    fill: $clr-accent;
    opacity: 0.3;
  }

  .tableTitleLogo > g > rect {
    stroke: $clr-accent;
  }
}