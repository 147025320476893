@import "../../../common/styles/colors.scss";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 10px;

  .title {
    display: flex;
    align-items: center;
    margin-right: 10px;
    min-width: 95px;
    .titleIcon {
      width: 35px;
      height: 35px;

      & > circle {
        fill: $clr-accent;
        opacity: 0.2;
      }
    
      & > path {
        stroke: $clr-accent;
      }
    }
    .titleText {
      margin-left: 4px;
      font-size: 12px;
      .titleTextTop {
        font-weight: bold;
        color: $clr-blue-v2;
      }
    }
  }

  .buttonActivate,
  .buttonDelete,
  .buttonAdd {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    min-width: 90px;
    font-size: 16px;
    height: 35px;
    border-radius: 8px;
  }

  .buttonAdd {
    background-color: $clr-accent;
    border: none;
    &:not([disabled]):hover {
      border: $clr-accent;
      outline: none;
    }
    .buttonAddName {
      margin: 0 10px;
    }
  }

  .buttonsIsCheckGroup {
    display: flex;
    .buttonActivate {
      background-color: $clr-accent;
      border: none;
      &:not([disabled]):hover {
        border: $clr-accent;
        outline: none;
      }
    }

    .buttonDelete {
      color: $clr-accent-dark;
      background: none;
      margin-right: 10px;
      cursor: pointer;

      .trashIcon {
        margin-right: 5px;
      }
    }
  }

  .filterControlWrapper {
    display: flex;
    align-items: center;
    width: calc(100vw - 485px);
    min-width: 536px;
    .countInfoWrapper {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .countInfoField {
        background-color: $clr-blue-transparent;
        width: 40px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        margin-right: 5px;
        border-radius: 8px;
      }
      .countInfoTitle {
        min-width: 106px;
      }
    }
  }
}
