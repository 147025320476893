@import "../../../common/styles/colors.scss";

.wrapper {
  width: 400px;
  height: 332px;

  .infoArea {
    display: flex;
    border: 1px dashed $clr-light-grey;
    border-radius: 8px;
    height: 204px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .infoAreaSuccess,
    .infoAreaUpload,
    .infoAreaReject {
      width: 100%;
    }

    .uploadIcon,
    .rejectIcon,
    .successIcon {
      width: 60px;
      height: 60px;
    }

    .uploadIcon {
      & > circle {
        fill: $clr-accent;
        opacity: 0.2;
      }

      & > path {
        stroke: $clr-accent;
        stroke-width: 1;
      }
    }

    .loadingWrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .infoAreaTop {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .uploadText {
        font-size: 18px;
        margin-top: 15px;
        color: $clr-blue-steel-dark;
        text-align: center;
      }

      .uploadSubText {
        margin-top: 10px;
        font-size: 12px;
        color: $clr-light-blue;
      }
    }

    .infoAreaBottom {
      margin-top: 20px;
      text-align: center;
    }
  }

  .buttonUpload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    height: 35px;
    border-radius: 8px;
    border: none;
    &:not([disabled]):hover {
      border: $clr-accent;
      outline: none;
    }

    background-color: $clr-accent;
    color: $clr-white;
  }
}
