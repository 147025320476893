@import '../../../common/styles//colors.scss';
@font-face {
    font-family: 'Space-Grotesk';
    src: local('Space-Grotesk'), url('../../../common/fonts/SpaceGrotesk-VariableFont_wght.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

.fieldWrapper {
    display: flex;
    //justify-content: space-between;
    height: 63px;

    .fieldName {
        margin-top: 6px;
        color: $clr-grey-blue;
    }

    .field,
    .selectField,
    .dataField {
        width: 100%;
    }

    .selectField {
        border-radius: 8px;
    }
}

.errorWrap {
    display: flex;
    flex-direction: column;
}

.errorText {
    font-size: 12px;
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;

    .buttonSave,
    .buttonDiscard {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        font-size: 18px;
        height: 35px;
        border-radius: 8px;
        border: none;
        &:not([disabled]):hover {
            border: $clr-accent;
            outline: none;
        }
    }

    .buttonSave {
        background-color: $clr-accent;
        color: $clr-white;
    }
    .buttonDiscard {
        color: $clr-dimgray;
        background: none;
        margin-right: 10px;
        cursor: pointer;
    }
}

.switchFieldWrapper {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;

    .switchTitle {
        margin-right: 182px;
    }
}

.optionsWrapper {
    .buttonOptionAdd {
        border-radius: 8px;
        padding: 0 8px;
    }

    .fieldOptions {
        width: 260px;
    }

    .fieldOptionsName {
        width: 214px;
    }

    .optionsList {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .optionItem {
            display: flex;
            align-items: center;
            .optionItemName {
                width: 262px;
                padding: 3px 10px;
                text-align: center;
                border: 1px solid $clr-accent;
                color: $clr-accent;
                border-radius: 8px;
                margin-bottom: 10px;
            }

            .optionItemDelete {
                display: flex;
                align-items: center;
                height: 30px;
                margin: 0 6px 10px 14px;
                cursor: pointer;
            }

            &:last-child {
                margin-bottom: 20px;
            }
        }
    }
}

.icon {
    width: 20px;
    height: 70px;
    object-fit: contain;
    margin: -31px 0 0 14px;
    cursor: pointer;
}

.tabFieldWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selectFieldTab {
    width: 270px !important;
}

.inputQuantity {
    width: 100%;
    //margin: 0 182px 0 0;
}

.wrapButton {
    min-height: 35px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    justify-content: flex-end;
}

.saveButton {
    cursor: pointer;
    background-color: #e16d25;
    border: none;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    height: 35px;
    border-radius: 8px;
    box-shadow: none;
}

.clearButton {
    cursor: pointer;
    background: none;
    box-shadow: none;
    color: #575454;
    margin-right: 27px;
    font-size: 18px;
}
.inputUrlWrapper {
    display: flex;

    .urlButton {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        margin-right: 5px;
        border-radius: 8px;
        border: 1px solid $clr-grey-blue;

        &:hover {
            opacity: 0.8;
        }

        .downloadIcon {
            font-size: 20px;
            color: $clr-grey-blue;
            width: 22px;
        }
    }
}
.popup {
    width: 350px;
    padding: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.1px;
    color: #ffffff;
    position: fixed;
    bottom: 45px;
    right: -900px;
    z-index: 7;
    transition: all 0.4s;

    &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.1px;
        color: #ffffff;
    }

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &__mark_success {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 20px;
        height: 20px;
        background: white;
        border-radius: 50%;
        color: #4ce152;
        font-size: 14px;
        margin: 0 10px 0 0;
    }
    &__mark_error {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #ff4444;
        font-size: 16px;
        font-weight: 600;
        margin: 0 10px 0 0;
    }

    &.success {
        background: #4ce152;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
    }
    &.error {
        background: #ff4444;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        bottom: 110px !important;
    }
}

.active {
    right: 100px !important;
    transition: all 0.4s;
}

.errorMetaData {
    color: #ef5151;
    font-size: 16px;
    margin: 16px 0;
    text-align: center;
}
.saveButtonWine {
    cursor: pointer;
    background-color: $clr-dark-red;
    border-radius: 8px;
    border: 2px solid $clr-dark-red;
    background: $clr-dark-red;
    padding: 9.43px 16.165px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-width: 128px;
}

.td {
    border-bottom: 1px solid #E5E5E5;
    padding: 2px 0 1px 4px;
    margin: 0;
    font-family: 'Space-Grotesk', sans-serif;
    strong {
        font-family: 'Space-Grotesk', sans-serif;
        font-weight: bold;
    }
}

.wrapper {
    background-color: $clr-white;
    border-radius: 12px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 0 20px 20px 20px;
    //height: calc(100vh - 230px);
    overflow: auto;
}

.retailerFont {
    font-family: 'Space-Grotesk', sans-serif;
}

.odd {
    //background-color: #f2f2f2;
}

