@import '../../../common/styles/colors.scss';

.itemFormFieldForm {
    .ant-select .ant-select-selector {
        background-color: $clr-blue-transparent;
        border-radius: 8px;
    }

    .ant-switch {
        background-color: $clr-accent;
        border: $clr-accent;
    }

    .ant-switch-checked {
        background-color: $clr-green;
        border: $clr-green;
    }
}
