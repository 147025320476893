@import "../../styles/colors.scss";

nav {
  margin-top:30px;
}

nav a {
  text-decoration: none;
  font-size: 18px;
}
nav a:hover>.nav__link  {
  border-radius: 4px;
  border: 1px solid #FFF;
}

.nav__link {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 8px !important;
}

a.active>.nav__link {
  background-color: $clr-very-light-grey;
  color: $clr-blue-v2;
  text-decoration: none;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

a.active>.nav__link svg {
  color: $clr-blue-sea; 
}

a.active>.nav__link .darkRed svg {
  color: $clr-dark-red!important; 
}

a>.nav__link {
  text-decoration: none;
  margin: 0 15px;
  border-radius: 4px;
  border: 1px solid transparent;
  color: $clr-black;

  text-align: center;
  font-family: 'Inter-Regular', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
}

a.active>.nav__link>.icon>circle,
a.active>.nav__link>.icon_published>circle,
a.active>.nav__link>.icon-qrcode>circle,
a.active>.nav__link>.iconUpload>circle {
  fill: $clr-accent;
  opacity: 0.2;
}

a.active>.nav__link>.icon_qr {
  width: 34px;
  margin: 0 16px 0 2px;
  background: rgb(249, 226, 211);
  border-radius: 50%;
}

a.active>.nav__link>.wrapIcon {
  width: 36px;
  height: 36px;
  margin: 0 16px 0 2px;
  background: rgb(249, 226, 211);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_qr {
  width: 23px;
}

.wrapIcon {
  width: 36px;
  height: 36px;
  margin: 0 16px 0 2px;
  background: inherit;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.active>.nav__link>.icon path {
  fill: $clr-accent;
}

a.active>.nav__link>.iconUpload path,
a.active>.nav__link>.icon_published path {
  stroke: $clr-accent;
}

a.active>.nav__link>.icon rect {
  stroke: $clr-accent;
}

.nav__link>.icon,
.nav__link>.icon_published,
.nav__link>.iconUpload,
.nav__link>.iconItemForm {
  margin-right: 10px;
}

.nav__link>.icon_published {
  width: 36px;
  height: auto;
}

a.active>.nav__link>.iconItemForm>path {
  stroke: $clr-accent;
}

a.active>.nav__link>.iconItemForm>circle {
  fill: $clr-accent;
  opacity: 0.2;
}