*,
*:before,
*:after {
    box-sizing: border-box;
    font-family: 'Inter-Regular', sans-serif;
    font-weight: 400;
}

@font-face {
    font-family: 'ProximaNova-Bold';
    src: url('../../common/fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('../../common/fonts/ProximaNova-Bold.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'ProximaNova-Regular';
    src: url('../../common/fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('../../common/fonts/ProximaNova-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'ProximaNova-Semibold';
    src: url('../../common//fonts/ProximaNova-Semibold.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Inter-Regular';
    src: url('../../common/fonts/Inter-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Space-Grotesk';
    src: local('Space-Grotesk'), url('../../common/fonts/SpaceGrotesk-VariableFont_wght.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}

body {
    margin: 0;
    background-color: #EDEDED;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.ant-carousel ul .slick-dots.slick-dots-bottom,
:where(.css-dev-only-do-not-override-nllxry).ant-carousel .slick-dots-bottom {
    bottom: -15px!important;
}
:where(.css-dev-only-do-not-override-nllxry).ant-carousel .slick-dots li.slick-active button {
    background: #8F0A0E!important;
    opacity: 1!important;
}
:where(.css-dev-only-do-not-override-nllxry).ant-carousel .slick-dots li button {
    background: #8F0A0E!important;
    opacity: .5!important;
}