@import "../../../common/styles/colors.scss";

.productFilterFormSearch {
  display: flex;
  background-color: $clr-blue-transparent;
  align-items: center;
  min-width: 170px;
  width: calc(100vw - 1000px);
  min-height: 35px;
  border-radius: 8px;
  margin-left: 20px;
  .ant-input {
    border-radius: 0px;
    background: none;
    border: none;
    box-shadow: none;
    color: $clr-dark-blue;
  }
}

.productFilterFormSelectGroup {
  display: flex;
  align-items: center;
  height: max-content;
  .ant-select {
    border-radius: 8px;
  }
  
  .ant-select .ant-select-selector {
    background-color: $clr-blue-transparent;
    border-radius: 8px;
    height: 32px;
  }

  .ant-select-selection-item {
    font-size: 15px;
    color: $clr-dark-blue;
  }

  .ant-select-selection-placeholder {
    font-size: 15px;
  }

  .ant-select-clear {
    background: none;
  }
}

.ant-select-item-option-content {
  color: $clr-dark-blue;
  font-size: 15px;
}