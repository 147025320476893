@import "../../../common/styles/colors.scss";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 10px;

  .title {
    display: flex;
    margin-right: 10px;
    min-width: 120px;

    .titleIcon {
      width: 24px;
      height: 24px;
      margin-top: 4px;
    }
    .titleText {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      height: 25px;

      .titleTextTop {
        color: $clr-blue-v2;
        font-size: 16px;
        height: 11px;
        margin-bottom: 3px;
      }
      .titleTextTopWine {
        color: $clr-primary;
        font-size: 16px;
        height: 11px;
        margin-bottom: 3px;
      }
      .titleTextBottom {
        color: $clr-dark-grey;
        font-size: 12px;
        height: 10px;
      }
    }
  }

  .buttonDelete > div,
  .buttonDeleteWine > div {
    padding-top: 4px;
  }

  .buttonActivate, .buttonDelete, .buttonAdd, .buttonAddWine, .buttonDeleteWine {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    font-size: 16px;
    box-shadow: none;
    height: 35px;
    border-radius: 8px;
  }

  .buttonAdd {
    background-color: $clr-accent!important;
    border-radius: 8px;
    border: 2px solid $clr-accent;
  }

  .buttonAddWine {
    border-radius: 8px;
    border: 2px solid $clr-primary;
    background: $clr-primary!important;
    &:not([disabled]):hover {

      border-radius: 8px;
      border: 2px solid $clr-primary;
      background: $clr-primary;
    }
  }

  .buttonsIsCheckGroup {
    display: flex;
    .buttonActivate {
      background-color: $clr-accent!important;
      border: none;
      &:not([disabled]):hover {
        background-color: $clr-accent!important;
        border: $clr-accent;
        outline: none;
      }
    }
    .buttonDelete {
      color: $clr-accent-dark;
      background: none;
      margin-right: 10px;
      cursor: pointer;

      .trashIcon {
        margin-right: 5px;
      }
    }
    .buttonDeleteWine {
      color: $clr-primary;
      background: none;
      margin-right: 10px;
      cursor: pointer;

      .trashIcon {
        margin-right: 5px;
      }
      svg {
        color: $clr-primary
      }
    }
  }

  .filterControlWrapper {
    display: flex;
    align-items: center;
    width: calc(100vw - 485px);
    min-width: 536px;
    .countInfoWrapper {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .countInfoField,
      .countInfoFieldWine {
        background-color: $clr-blue-transparent;
        color: $clr-black;
        width: 40px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        margin-right: 5px;
        border-radius: 4px;
      }
      .countInfoFieldWine {
        background-color: $clr-primary!important;
        color: $clr-white;
      }
      .countInfoTitle {
        min-width: 76px;
        color: $clr-dark-grey;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
