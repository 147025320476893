@import '../../../common/styles/colors.scss';

.wrapper {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  .imageView {
    width: 180px;
    height: 180px;
  }

  .baseImage {
    width: 180px;
    height: 180px;
  }

  .inputLabel {
    cursor: pointer;
    text-align: center;
    margin-top: 5px;
    height: 25px;
    color: $clr-blue-steel;
    &:hover {
      color: $clr-dark-blue;
    }
    .inputFile {
      display: none;
    }
  }

  .imagePreview {
    position: relative;
    user-select: none;
    .deleteImageButton {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      color: $clr-grey;
    }
  }
}