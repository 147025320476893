@import "../../../common/styles/colors.scss";

.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  position: relative;

  .filterControlWrapper {
    display: flex;
    align-items: center;
    min-width: 536px;
    .countInfoWrapper {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .countInfoField {
        background-color: $clr-blue-transparent;
        width: 40px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        margin-right: 5px;
        border-radius: 8px;
      }
      .countInfoTitle {
        min-width: 76px;
      }
    }
  }

  .companySelect {
    width: 240px;
  }

  .apply {
    margin-right: 10px;
  }

  .buttonDelete,
  .buttonAdd {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    font-size: 16px;
    height: 35px;
    border-radius: 8px;
    box-shadow: none;
  }

  .buttonAdd {
    background-color: $clr-accent;
    border: none;
    &:not([disabled]):hover {
      border: $clr-accent;
      outline: none;
    }
    .buttonAddName {
      margin: 0 10px;
    }
  }

  .buttonsGroup {
    display: flex;
    gap: 10px;
    .buttonAdd {
      background-color: $clr-accent;
      border: none;
    }

    .buttonDelete {
      color: $clr-accent-dark;
      background: none;
      margin-right: 10px;
      border: none;
      cursor: pointer;

      .trashIcon {
        margin-right: 5px;
      }
    }

    .buttonDelete > div {
      padding-top: 4px;
    }
  }
}
