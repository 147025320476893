@import "../../../common/styles/colors.scss";

.fieldWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 63px;

  .fieldName {
    margin-top: 6px;
    color: $clr-grey-blue;
  }

  .inputUrlWrapper {
    display: flex;

    .urlButton {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      margin-right: 5px;
      border-radius: 8px;
      border: 1px solid $clr-grey-blue;

      &:hover {
        opacity: 0.8;
      }

      .downloadIcon {
        font-size: 20px;
        color: $clr-grey-blue;
      }
    }
  }

  .field,
  .selectField,
  .dataField {
    width: 305px;
  }
}

.imagesWrapper {
  display: flex;
  align-items: center;
  height: 320px;
  margin-bottom: 90px;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 25px;
  right: 25px;

  .buttonSave,
  .buttonDiscard {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    font-size: 16px;
    height: 35px;
    border-radius: 8px;
    border: none;
    &:not([disabled]):hover {
      border: $clr-accent;
      outline: none;
    }
  }

  .buttonSave {
    background-color: $clr-accent;
    color: $clr-white;
  }
  .buttonDiscard {
    color: $clr-dimgray;
    background: none;
    margin-right: 10px;
    box-shadow: none;
  }
}

.switchControl {
  display: flex;
  justify-content: space-between;

  .switchControlLeft {
    width: 40%;
  }

  .switchFieldWrapper {
    display: flex;
    justify-content: space-between;
  }
}

.dynamicFieldsWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

