@import "../../../common/styles/colors.scss";

.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;


  .contentWrapper {
    padding: 40px 60px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background-color: $clr-dark-blue;
  }

  .logo {
    height: 50px;
  }

  .infoWrapper {
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: $clr-white;

    .infoText {
      font-size: 32px;
      color: $clr-white;
    }

    .buttonLink {
      height: 48px;
      line-height: 48px;
      border-radius: 8px;
      text-align: center;
      width: 200px;
      color: $clr-white;
      background-color: $clr-accent;
    }
  }
}

.wineWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;


  .contentWrapper {
    padding: 40px 60px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background-color: #72070b;
  }

  .logo {
    height: 50px;
  }

  .infoWrapper {
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: $clr-white;

    .infoText {
      font-size: 32px;
      color: $clr-white;
    }

    .buttonLink {
      height: 48px;
      line-height: 48px;
      border-radius: 8px;
      text-align: center;
      width: 200px;
      color: $clr-white;
      background-color: $clr-primary;
    }
  }
}