@import '../../../common/styles/colors.scss';

.userCompanieSelect {
  width: 100%;
  .ant-select {
    width: 100%;
  }
  .ant-select .ant-select-selector {
    border-radius: 2px;
    border: 1px solid #D9D9D9;
    background: #EDEDED;

    /* drop-shadow/button-secondary */
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-select .ant-select-selector > span {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-select-arrow {
    color: rgba(0, 0, 0, 0.85);
  }
}
