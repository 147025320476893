@import '../colors.scss';

.ant-input, .ant-input-password {
  background-color: #ffffff!important;
  border-radius: 2px!important;
}

.ant-form-item-explain-error {
  font-size: 12px;
}

.ant-input-password .ant-input {
  background: #ffffff!important;
}

input.ant-input:-internal-autofill-selected {
  background-color: #ffffff!important;
}
