@import '../colors.scss';

.ant-modal {
    display: flex;
    justify-content: center;
    min-width: 650px;
}

.ant-modal-content {
    border-radius: 8px;
    // min-height: 730px;
    .ant-modal-header {
        height: 56px;
        border-radius: 8px;
        padding: 16px 24px;
    }
}

.ant-modal .ant-modal-header .ant-modal-title {
    margin-right: 30px;
    min-width: 500px;
    max-width: 568px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.ant-modal-content {
    padding: unset!important;
}
.ant-modal-body {
    padding: 24px;
}