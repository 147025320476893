@import "../../../common/styles/colors.scss";

.tableWrapper {
  height: 292px;
}
.wineTableWrapper {
  min-height: 292px;
  height: 70vh;
}

.table {
  .mainCheckbox {
    font-size: 18px;
    color: $clr-grey-dark;
    position: relative;
    // &::before {
    //   content: "#";
    //   display: block;
    //   position: absolute;
    //   left: 22px;
    //   z-index: 20;
    // }
  }

  .emptyIcon {
    & > circle {
      opacity: 0.2;
    }
  }

  .buttonStatusInvalidate,
  .buttonStatusValidate {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 26px;
    font-size: 10px;
    border-radius: 30px;
    text-transform: capitalize;
    .buttonIcon {
      margin-right: 8px;
    }
  }

  .buttonStatusValidate {
    background-color: $clr-dark-lime-green;
    color: $clr-white;
    border: none;
    box-shadow: none;
  }
  .buttonStatusInvalidate {
    background-color: $clr-strong-orange;
    color: $clr-white;
    border: none;
    box-shadow: none;
  }

  .quantity,
  .date,
  .draftItemInfo {
    min-width: 50px;
  }

  .gtin,
  .activeItemInfo {
    width: 120px;
  }

  .descriptor {
    width: 160px;
  }

  .brand {
    width: 80px;
  }

  .description {
    width: 26vw;
  }

  .descriptionAdmin {
    width: 15vw;
  }

  .description,
  .descriptionAdmin,
  .brand,
  .companyName {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.draftItemInfo {
  &:hover {
    color: $clr-blue-steel;
    cursor: pointer;
  }
}

.footerWrapper {
  display: flex;
  justify-content: flex-end;
  .pagination {
    margin: 10px 0 8px 0;
  }
}

@media (min-width: 600px) and (max-width: 1432px) {
  .table {
    .companyName,
    .description,
    .descriptionAdmin {
      width: 150px;
    }
    .brand,
    .descriptor {
      width: 70px;
    }
  }
}

@media (min-width: 600px) and (max-width: 1235px) {
  .table {
    .gtin,
    .activeItemInfo {
      width: 57px;
    }
    .companyName,
    .description,
    .descriptionAdmin {
      width: 100px;
    }
    .brand,
    .descriptor {
      width: 57px;
    }
  }

  .brand {
    padding-left: 5px;
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .table {
    .companyName,
    .description,
    .descriptionAdmin {
      width: 80px;
    }
  }

  .date {
    margin-left: -10px;
  }

  .quantity {
    margin-left: -5px;
  }
}
.headerLogo {
  margin-right: 10px;

  & > circle {
    fill: $clr-accent;
    opacity: 0.2;
  }

  & > g > rect {
    stroke: $clr-accent;
  }
  width: 36px;
  height: 36px
}
.buttonLegallyInvalidate, 
.buttonLegallyValidate{
  display: flex;
  padding: 7.019px 12.478px;
  align-items: center;
  max-height: 20px;
  gap: 7.799px;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-size: 10.918px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.157px;
}
.buttonLegallyInvalidate {
  border-radius: 1.56px;
  border: 0.78px solid var(--calendula-gold-3, #FFE58F);
  background: var(--calendula-gold-1, #FFFBE6);
}
.buttonLegallyValidate {
  border-radius: 1.56px;
  border: 0.78px solid var(--polar-green-3, #B7EB8F);
  background: var(--polar-green-1, #F6FFED);
}