@import "../../../common/styles/colors.scss";

.tableWrapper {
//   height: calc(100vh - 552px);
}

.table {
  .mainCheckbox {
    font-size: 18px;
    color: $clr-grey-dark;
    position: relative;
    // &::before {
    //   content: "#";
    //   display: block;
    //   position: absolute;
    //   left: 22px;
    //   z-index: 20;
    // }
  }

  .emptyIcon {
    & > circle {
      opacity: 0.2;
    }
  }

  .quantity,
  .date,
  .activeItemInfo {
    min-width: 50px;
  }

  .gtin,
  .activeItemInfo {
    width: 120px;
  }

  .descriptor {
    width: 160px;
  }

  .brand {
    width: 80px;
  }

  .activeItemInfo {
    &:hover {
      color: $clr-blue-steel;
      cursor: pointer;
    }
  }

  .description {
    width: 26vw;
  }

  .descriptionAdmin {
    width: 15vw;
  }

  .description,
  .descriptionAdmin,
  .brand,
  .companyName {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .emptyField {
    width: 100px;
  }
}

.footerWrapper {
  display: flex;
  justify-content: flex-end;
  .pagination {
    margin: 10px 0 8px 0;
  }
}

@media (min-width: 600px) and (max-width: 1432px) {
  .table {
    .companyName,
    .description,
    .descriptionAdmin {
      width: 150px;
    }
    .brand,
    .descriptor {
      width: 70px;
    }
  }
}

@media (min-width: 600px) and (max-width: 1235px) {
  .table {
    .gtin,
    .activeItemInfo {
      width: 57px;
    }
    .companyName,
    .description,
    .descriptionAdmin {
      width: 100px;
    }
    .brand,
    .descriptor {
      width: 57px;
    }
  }

  .brand {
    padding-left: 5px;
  }
}

@media (min-width: 600px) and (max-width: 1046px) {
  .table {
    .companyName,
    .description,
    .descriptionAdmin {
      width: 80px;
    }
  }

  .date {
    margin-left: -10px;
  }

  .quantity {
    margin-left: -5px;
  }
}
