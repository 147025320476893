@import "../colors.scss";

.ant-table-thead .ant-table-cell {
  background-color: $clr-light-grey!important;
  color: $clr-dark-blue;
  font-weight: 500!important;
  font-size: 11px;
  height: 45px;
  &::before {
    display: none;
  }
}

.ant-table-header {
  overflow: visible;
}

.ant-table-header > table {
  table-layout: unset !important;
}

.ant-table-body > table {
  table-layout: unset !important;
}

.ant-table-thead > tr > th {
  padding: 5px 10px;

  @media (max-width: 1300px) {
    &:nth-child(8) {
      padding-left: 6px;
    }
  
    &:nth-child(7) {
      padding: 0;
    }
  }
}

.ant-table-tbody > tr.ant-table-measure-row > td {
    padding: 0 !important;
}

.ant-table-tbody > tr > td {
  font-size: 14px;
  padding: 6px 10px!important;
  word-break: break-all;
  max-width: 385px;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: auto;
}

.ant-table-expanded-row {
  .ant-table-thead .ant-table-cell {
    background-color: $clr-lavender;
  }

  .ant-table-row .ant-table-cell {
    background-color: $clr-light-iceblue;
  }

  .ant-table-tbody .ant-table-row > td.ant-table-cell-row-hover {
    background-color: $clr-light-iceblue;
  }

  .ant-table-cell {
    padding: 0;
  }

  .ant-table-thead > tr > th {
    white-space: nowrap;
    padding: 5px 10px;
  }

  .ant-table-tbody > tr > td {
    font-size: 14px;
    padding: 6px 10px;
    word-break: break-all;
  }
}