@import "../../../common/styles/colors.scss";

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 620px;
  min-width: 400px;
  margin: 0 40px;
  height: 543px;
  align-items: center;

  .formTitle {
    width: 100%;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 50px;
    font-weight: bold;
    color: $clr-accent;
  }

  .checkbox {
    margin-top: 25px;
    display: flex;
    align-items: unset;
    .checkboxTitle {
      font-size: 16px;
      color: $clr-blue-sea;
    }
  }

  .fields {
    width: 100%;
    .fieldWrapper {
      height: 94px;
      margin-bottom: 15px;
      .field {
        height: 48px;
        color: $clr-dark-blue;
      }
      .fieldTitle {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .buttonGroup {
    margin-top: 30px;
    width: 100%;
    .button {
      width: 100%;
      height: 48px;
      font-size: 18px;
      background-color: $clr-accent;
      border: none;
      border-radius: 8px;
      &:not([disabled]):hover {
        border: $clr-accent !important;
        outline: none;
      }
    }
  }

  .buttonLink {
    text-align: center;
    margin-top: 35px;
    font-size: 13px;
    color: $clr-smoke;
    text-decoration: none;
    border: none;
    box-shadow: none;
  }
}
