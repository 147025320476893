@import "../../styles/colors.scss";
.wrapper, 
.wineWrapper {
.modal {
  top: 20vh;
}

.modalIcon {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.modalInfo {
  margin: 30px;
  min-width: 350px;

  .modalInfoText,
  .modalInfoSubText {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .modalInfoText {
    font-size: 22px;
    margin-bottom: 5px;
    color: $clr-dark-blue;
  }

  .modalInfoSubText {
    font-size: 14px;
    color: $clr-dark-blue;
  }
}


.buttonGroup {
  display: flex;
  justify-content: space-around;
  margin: 30px 0;

  .buttonOk,
  .buttonCancel {
    min-width: 160px;
    height: 42px;
    border-radius: 8px;
    &:hover {
      opacity: 0.9;
    }
  }

  .buttonOk {
    background-color: $clr-accent!important;
    border: none;
    &:not([disabled]):hover {
      border: $clr-accent;
      outline: none;
    }
  }

  .buttonCancel {
    color: $clr-grey-blue!important;
    &:hover {
      border: 1px solid $clr-dark-blue !important;
    }
    &:not([disabled]):hover {
      border: $clr-dark-blue;
    }
  }
}
}
.wineWrapper {
  .buttonGroup {
    .buttonOk {
      background-color: $clr-dark-red!important;
    }
  }
  .modalIcon svg, .modalIcon svg circle {
    color: $clr-dark-red!important;
    fill: $clr-dark-red!important;
    stroke: $clr-dark-red!important;
  }
  .modalIcon svg path {
    fill: $clr-dark-red!important;
  }
}