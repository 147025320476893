@import '../../../common/styles/colors.scss';

.itemFormFieldFormModal {
    top: 25px;
    .ant-modal-content {
        width: 650px;
        .ant-modal-close-x {
            color: $clr-white;
        }
        .ant-modal-header {
            background-color: $clr-blue-steel-dark;
        }

        .ant-modal-title {
            text-align: center;
            padding-left: 30px;
            color: $clr-white;
        }
    }
}
