@import "../../../common/styles/colors.scss";

.tableWrapper {
  min-height: calc(100vh - 164px);
}

.table {
  .mainCheckbox {
    font-size: 18px;
    color: $clr-grey-dark;
    position: relative;
    // &::before {
    //   content: "#";
    //   display: block;
    //   position: absolute;
    //   left: 22px;
    //   z-index: 20;
    // }
  }

  .avatar {
    display: block;
    border: 1px solid $clr-grey-dark;
    border-radius: 50%;
    width: 36px;
    height: 36px;
  }

  .companyInfo {
    &:hover {
      color: $clr-blue-steel;
      cursor: pointer;
    }
  }

  .buttonStatusActive,
  .buttonStatusInactive {
    width: 76px;
    height: 26px;
    font-size: 10px;
    border-radius: 30px;
    box-shadow: none;
    border: none;
  }

  .buttonStatusActive {
    background-color: $clr-green;
  }

  .buttonStatusInactive {
    background-color: $clr-gainsboro;
    color: $clr-dark-blue;
  }
}

.footerWrapper {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .buttonDelete {
    display: flex;
    align-items: center;
    min-width: 160px;
    font-size: 16px;
    height: 35px;
    box-shadow: none;
    background-color: $clr-accent;
    border: none;
    border-radius: 8px;
    &:not([disabled]):hover {
      border: $clr-accent;
      outline: none;
    }
  }

  .minusIcon {
    margin-right: 12px;
  }

  .pagination {
    margin: 4px 0;
  }
}

.ant-table-cell {
  padding-left: 10px;
}
