@import '../common/styles/colors.scss';


.ant-select .ant-select-selector {
    border-radius: 2px;
    border: 1px solid #D9D9D9;
    background: #EDEDED;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

.ant-switch-checked {
    background-color: $clr-green;
    border: $clr-green;
}