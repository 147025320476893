@import '../../../common/styles/colors.scss';

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;

  .fieldWrapper {
    display: flex;
    justify-content: space-between;
    height: 54px;

    .fieldName {
      margin-top: 6px;
      color: $clr-grey-blue;
    }

    .field {
      width: calc(100vw - 506px);;
    }
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 25px;
    right: 25px;

    .buttonSave,
    .buttonDiscard {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100px;
      font-size: 16px;
      height: 35px;
      border-radius: 8px;
      border: none;
      &:not([disabled]):hover {
        border: $clr-accent;
        outline: none;
      }
    }

    .buttonSave {
      background-color: $clr-accent;
      color: $clr-white;
    }
    .buttonDiscard {
      color: $clr-dimgray;
      background: none;
      margin-right: 10px;
      box-shadow: none;
    }
  }
}