@import "../../../common/styles/colors.scss";

.listTbodyItemsField {
  overflow: scroll;
  overflow-y: unset;
  margin: 20px 0;
  padding: 0 5px;
  height: calc(100vh - 250px);
  border: 1px dashed $clr-light-grey;
  width: 100%;
}

.uploadWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .uploadIcon {
    width: 60px;
    height: 60px;
    & > circle {
      fill: $clr-accent;
      opacity: 0.2;
    }

    & > path {
      stroke: $clr-accent;
      stroke-width: 1;
    }
  }

  .uploadText {
    font-size: 18px;
    margin-top: 15px;
    color: $clr-blue-steel-dark;
  }
}