@import '../../../common/styles/colors.scss';
@import '../../../common/styles/colors-retailer.scss';

.retailerFont {
    table {
        font-family: 'Space-Grotesk', sans-serif;
    }
    tr {
        font-family: 'Space-Grotesk', sans-serif;
    }
    td {
        font-family: 'Space-Grotesk', sans-serif;    
    }
    font-family: 'Space-Grotesk', sans-serif;
}

h1 {
    margin: 0 0 30px 0;
    font-family: 'Space-Grotesk', sans-serif;
}

h2 {
    color: $clr-primary;
    font-size: 30px;
    text-transform: capitalize;
    font-weight: bold;
    font-family: 'Space-Grotesk', sans-serif;
}
