@import '../../common/styles/colors.scss';

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 70px);
}

.headerLogo {
  margin-right: 10px;

  & > circle {
    fill: $clr-accent;
    opacity: 0.2;
  }

  & > path {
    stroke: $clr-accent;
  }
}
