@import "../../styles/colors.scss";

.sidebar {
  display: flex;
  width: 250px;
  min-width: 250px;
  min-height: 100vh;
  background-color: $clr-white;
  flex-direction: column;
  justify-content: space-between;

  .header {
    height: 75px;
    position: relative;
    background-color: $clr-grey-blue;
    display: flex;
    align-items: center;
    justify-content: center;

    .headerTitle {
      display: flex;
      flex-direction: column;
      color: $clr-white;
      font-size: 14px;
      margin-top: 7px;

      .headerTitleTop {
        position: absolute;
        top: 15px;
        left: 114px;
        font-size: 9px;
        color: $clr-light-blue;
      }

      .headerTitleBottom {
        display: flex;
        font-size: 16px;
        font-weight: bold;

        .titleBottomName {
          font-size: 20px;
          font-weight: bold;
        }

        .headerLogo {
          margin-right: 10px;
          height: 35px;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;

    .logout,
    .user {
      display: flex;
      align-items: center;
      margin: 5px;
      padding: 10px;
      border-radius: 8px;
      background-color: $clr-white;
      color: $clr-dark-blue;

      .userTitle,
      .logoutTitle {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 18px;
      }
    }

    .logout {
      &:hover {
        opacity: 0.8;
      }
    }

    .iconAvatar, .iconLogout {
      margin-right: 8px;
      min-width: 36px;
      min-height: 36px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }

    .iconLogout {
      & > path {
        fill: $clr-accent;
      }
    }

    .iconAvatar {
      & > path, &  > circle:last-child {
        stroke: $clr-accent;
      }
    }
  }
}
.outlinedIcon {
  padding-left: 20px;
  padding-right: 10px;
}

.outlinedIcon svg {
  width: 24px;
  height: 24px;
}
.userCompaniesSelect {
  background: $clr-dark-blue;
  padding: 23px 15px;
}
