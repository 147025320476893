@import "../../../common/styles/colors.scss";

.form {
  width: 600px;
  margin: 0 auto;

  .formTitle {
    text-align: center;
    line-height: 35px;
    background-color: $clr-gainsboro;
    height: 35px;
    border-radius: 20px;
  }

  .buttonGroup {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .button {
      width: 290px;
      height: 35px;
      background-color: $clr-accent;
      border: none;
      border-radius: 8px;
      &:not([disabled]):hover {
        border: $clr-accent !important;
        outline: none;
      }
    }
  }

  .fieldWrapper {
    height: 74px;
  }

  .field {
    height: 35px;
  }

  .companyInfoFields {
    display: flex;
    justify-content: space-between;

    .companyInfoFieldsLeft,
    .companyInfoFieldsRight {
      width: 49%;
      margin-top: 20px;
    }

    .companyInfoFieldsRightWrapper {
      display: flex;
      justify-content: space-between;
    }

    .companyInfoFieldsRightWrapperLeft,
    .companyInfoFieldsRightWrapperRight {
      width: 48%;
    }
  }

  .contactPerson {
    margin-top: 20px;

    .contactInfoFields {
      display: flex;
      margin-top: 20px;

      .contactInfoFieldsLeft,
      .contactInfoFieldsRight {
        width: 50%;
      }

      .contactInfoFieldsRight {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
