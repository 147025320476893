@import "../../styles/colors.scss";

.container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 45px;
  height: 84px;
  background-color: $clr-mostly-white;
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px; 
}