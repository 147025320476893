@import '../../../common/styles/colors.scss';

.ant-modal-centered .ant-modal .ant-modal-content {
    // min-height: 740px;
    top: 40px;
}

.digitalAssetsForm {
    min-height: 595px;

    .ant-select .ant-select-selector {
        background-color: $clr-blue-transparent;
        border-radius: 8px;
    }

    .ant-picker-clear {
        background: $clr-gray-transparent;
    }

    .ant-switch {
        background-color: $clr-accent;
        border: $clr-accent;
    }

    .ant-switch-checked {
        background-color: $clr-green;
        border: $clr-green;
    }

    .ant-picker {
        border-radius: 8px;
    }
}
