@import '../../../common/styles/colors.scss';

.itemFormFieldForm {
    .ant-select .ant-select-selector {
        background-color: $clr-blue-transparent;
        border-radius: 8px;
    }

    .ant-switch {
        background-color: $clr-accent;
        border: $clr-accent;
    }

    .ant-switch-checked {
        background-color: $clr-green;
        border: $clr-green;
    }
}

//Ant Tabs
.ant-tabs-tab {
    color: #000!important;

    text-align: center;
    font-size: 16px!important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.ant-tabs-tab.ant-tabs-tab-active {
    background: $clr-blue-sea;
    border-radius: 8px;
    border: 2px solid $clr-blue-sea;
    padding: 10px 16px;
    min-width: 100px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 12px!important;
    margin-bottom: 12px!important;
}
.wine .ant-tabs-tab.ant-tabs-tab-active {
    background: $clr-dark-red!important;
    border: 2px solid $clr-dark-red!important;
      margin-top: 12px;
    margin-bottom: 12px;
}
.ant-tabs-ink-bar {
  background: transparent!important;
}
.ant-tabs-tab-btn {
    text-transform: capitalize;   
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $clr-white!important;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.12px;
    text-transform: capitalize;
}
.ant-tabs-tab:hover,
.ant-tabs-tab-btn:active, .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:active, .ant-tabs-tab-remove:focus {
    color: #000!important;  
}

.itemFormInputNumberField_fieldWrapper__Tix90 {
    justify-content: start!important;
    display: block
}
.itemFormInputNumberField_fieldWrapper__Tix90 .itemFormInputNumberField_field__eys8o, .itemFormInputNumberField_fieldWrapper__Tix90 .itemFormInputNumberField_selectField__pRUEM,
.itemFormInputNumberField_fieldWrapper__Tix90 {
    width: 100%
}
.itemFormInputDateTimeField_fieldWrapper__-YSoH {
    justify-content: start;
}
.inputFieldTitle_inputWrapper__273As {
    width: 100%;
}
.ant-input-focused, .ant-input:focus {
    border-color: $clr-dark-grey;
    box-shadow: 0 0 0 2px rgba(119, 119, 119,.2)
}
.ant-input:hover {
    border-color: $clr-dark-grey;
    border-right-width: 1px;
}
:where(.css-dev-only-do-not-override-nllxry).ant-input-disabled, :where(.css-dev-only-do-not-override-nllxry).ant-input[disabled] {
    color: #334A61;
}