@import "../../styles/colors.scss";

.sidebar {
  display: flex;
  width: 250px;
  min-width: 250px;
  min-height: 100vh;
  background-color: $clr-white;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  z-index: 1;
  position: relative;

  .header {
    position: relative;
    background-color: $clr-white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .headerLogo {
    width: 100%;
    height: 84px;
    padding: 15px 45px;
  }

  .navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
    margin-bottom: 8px !important;
    
    a {
        color: $clr-black;
        width: 100%;
        flex-direction: row;
        display: flex;
    }
  }

  .menuEntry {
    color: $clr-black;
    width: 100%;
    flex-direction: row;
    display: flex;
    margin: 20px 0 20px 20px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 400;

    .logout,
    .user {
      display: flex;
      align-items: center;
      margin: 5px;
      padding: 10px;
      border-radius: 8px;
      color: $clr-black;

      .userTitle,
      .logoutTitle {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $clr-black;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .logout {
      &:hover {
        opacity: 0.8;
      }
    }

    .iconAvatar, .iconLogout {
      margin-right: 8px;
      min-width: 36px;
      min-height: 36px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }

    .iconLogout {
      & > path {
        fill: $clr-accent;
      }
    }

    .iconAvatar {
      & > path, &  > circle:last-child {
        stroke: $clr-accent;
      }
    }
  }
}
.outlinedIcon {
  padding-left: 20px;
  padding-right: 10px;
  padding: 0;
  margin-right: 7px;
  vertical-align: middle;
}

.outlinedIcon svg {
  width: 24px;
  height: 24px;
}

.userCompaniesSelect {
  background: $clr-very-dark-red;
  padding: 23px 15px;
  height: 90px;
}


a.active div {
    color: red;
    background: red;
    border: 1px solid red;
}