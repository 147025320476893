@import "../../../common/styles/colors.scss";

.tableWrapper {
  height: 238px;
  margin-bottom: 10px;

  .emptyStart {
    width: 20px;
  }

  .staticWrapper {
    width: 90px;
    margin: 0 auto;
    .staticIcon {
      line-height: 26px;
      width: 76px;
      height: 26px;
      font-size: 10px;
      border-radius: 30px;
      margin: 0 auto;
      background-color: $clr-accent;
      color: $clr-white;
    }
  }

  .itemTabName, .fieldTypeName {
    width: 120px;
  }

  .name {
    width: 160px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .itemTabName {
    margin-right: 14px;
  }

  .fieldTypeName {
    margin-right: 10px;
  }
}
