@import "../../../common/styles/colors.scss";

.tableWrapper {
  min-height: calc(100vh - 470px);
  max-height: calc(100vh - 470px);
  overflow-y: hidden;
}

.table {
  .mainCheckbox {
    font-size: 18px;
    color: $clr-grey-dark;
    position: relative;
    // &::before {
    //   content: "#";
    //   display: block;
    //   position: absolute;
    //   left: 22px;
    //   z-index: 20;
    // }
  }

  .fieldTypeName, .itemTabName {
    width: 120px;
  }

  .fieldInfo {
    width: 160px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
    &:hover {
      color: $clr-blue-steel;
      cursor: pointer;
    }
  }

  .itemTabName {
    margin-right: 14px;
  }

  .fieldTypeName {
    margin-right: 10px;
  }

  .buttonStatusActive,
  .buttonStatusInactive {
    width: 76px;
    height: 26px;
    font-size: 10px;
    border-radius: 30px;
    margin: 0 5px;
  }

  .buttonStatusActive {
    background-color: $clr-green;
    border: none;
    box-shadow: none;
  }
  .buttonStatusInactive {
    background-color: $clr-gainsboro;
    color: $clr-dark-blue;
    border: none;
    box-shadow: none;
  }
}

.footerWrapper {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .pagination {
    margin: 4px 0;
  }
}
