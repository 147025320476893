@import "../../styles/colors-vindix.scss";
@import "../../styles/colors.scss";

.wrapper,
.wineWrapper {
  display: flex;
  width: 100vw;
  height: max-content;
  position: relative;
  z-index: 0;

  .contentWrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .content {
      padding: 30px 30px 90px 30px;
    //   background: $clr-lighter-gray;
      width: 100%;
    }
  }
}

.wrapper .headerLogo svg,
.wineWrapper .headerLogo svg{
  width: 28px;
  height: 28px;
}
.wineWrapper .headerLogo {
  color: $clr-dark-red!important
}

.wrapper .headerLogo {
  color: $clr-blue-sea!important
}