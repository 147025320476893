@import "../../../common/styles/colors.scss";

.fieldWrapper {
  display: flex;
  justify-content: space-between;
  height: 50px;
  width: 100%;

  .fieldName {
    margin-top: 6px;
    width: 150px;
    color: $clr-grey-blue;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .selectField {
    width: 305px;
  }

  .selectField {
    border-radius: 8px;
  }
}

.fieldWrapperIsSize {
  width: 22.5%;
}
