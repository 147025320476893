@import '../../../common/styles/colors.scss';

.draftProductsFormModal {
    position: relative;

    .ant-modal-body {
        padding-top: 0;
        width: calc(100vw - 222px);
        min-width: 800px;
    }

    .ant-modal-content {
        height: 95vh;
        margin-top: -90px;
    }

    .ant-modal-header {
        display: flex;
        align-items: center;
        border-radius: 8px 8px 0 0;
    }
}
